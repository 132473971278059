@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.s-indu-we-work {
    position: relative;

    &::before {
        content: '';

        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transform: skewY(-10deg);

        background-color: $color-laguna-light;

        @media (min-width: $medium-size) {
            transform: skewY(-7deg);
        }
    }
    &_white {
        &::before {
            display: none;
        }
    }
}
.s-indu-we-work__bgs {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}
.s-indu-we-work__bgs-container {
    height: 100%;

    position: relative;
}
.s-indu-we-work__container {
    padding: {
        top: 100px;
        bottom: 100px;
    };

    position: relative;

    overflow: hidden;

    @media (min-width: $large-size) {
        padding: {
            top: 200px;
            bottom: 200px;
        };
    }
    
    &_min-padding {
        padding-top: 50px;
    }
}
.s-indu-we-work__wr-bg-img {
    width: 320px;
    height: 280px;

    @include img-wrap;

    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    transform: translateX(60px);

    opacity: .4;

    @media (min-width: $large-size) {
        width: 700px;
        height: 500px;

        transform: translateX(150px);
    }

    &_bot {
        display: none;

        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        transform: rotate(180deg) translateX(100px);

        @media (min-width: $large-size) {
            display: block;

            width: 650px;
            height: 500px;
        }
    }

    img, 
    svg {
        object-fit: contain;
    }
}

.s-indu-we-work__head {
    margin-bottom: 30px;

    @media (min-width: $small-size) {
        margin-bottom: 40px;
    }
}
.s-indu-we-work__heading {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 20px;
}
.s-indu-we-work__heading-italic {
    margin: {
        left: 8px;
        right: 8px;
    };
}
.s-indu-we-work__head-text {
    max-width: 715px;
}

.s-indu-wr-work__list {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media (min-width: $smallest-size) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: $medium-size) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.bl-ind-work-cta {
    width: 100%;
    padding-bottom: 60%;

    position: relative;

    color: #ffffff;
    text-align: center;

    @media (min-width: $smallest-size) {
        padding-bottom: 0;
    }
}
.bl-ind-work-cta__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    background-color: $color-orange-dark;
}
.bl-ind-work-cta__link {
    color: $color-orange-dark;
}
.bl-ind-work-cta__text {
    max-width: 180px;
    margin-bottom: 10px;

    @media (min-width: $large-size) {
        margin-bottom: 30px;
    }
}
